import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
  .use(resourcesToBackend((language, namespace, callback) => {
    let lang = language;
    if (language.toLowerCase() === 'de') {
      lang = 'german';
    }
    import(`../../../../assets/locales/etoro/${lang}/translation.json`)
      .then((resources) => {
        callback(null, resources);
      })
      .catch((error) => {
        callback(error, null);
      });
  }))
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: '../../../../assets/locales/etoro/{{lng}}/translation.json',
    },
    initImmediate: false,
  });
