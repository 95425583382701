import { useState, useEffect } from 'react';
import errorBg from '../../assets/bg-error.svg';
import Styles from './styles.module.scss';

import ButtonComponent from '../Buttons/ButtonComponent';
import withComponentName from '../../decorators/withComponentName';

import AssetNotFound from './AssetNotFound';

const Buttons = () => {
  const [content, setContent] = useState(
    <ButtonComponent
      value="Go to Homepage"
      green
      path="/"
    />,
  );

  useEffect(() => {
    if (document.referrer) {
      const parts = document.referrer.split('://')[1].split('/');
      const urlHost = parts[0];

      if (['0.0.0.0', 'cityfalcon.com'].some((host) => urlHost.includes(host))) {
        setContent(
          <ButtonComponent
            value="Go back"
            green
            path={document.referrer}
          />,
        );
      }
    }
  }, []);

  return content;
};

const ErrorPage = ({
  error, code, note, isEtoro, railsContext, piwikEnabled,
}) => {
  useEffect(() => {
    if (document.referrer.match('help')) {
      document.getElementsByTagName('html')[0].classList.add('help_error_page');
    }
  }, []);

  if (isEtoro) {
    return (
      <AssetNotFound
        error={error}
        railsContext={railsContext}
        piwikEnabled={piwikEnabled}
      />
    );
  }

  return (
    <div className={Styles.error_page}>
      <div className={`${Styles['index-header']} clearfix`} />
      <img className={Styles.error_bg} src={errorBg} alt="Error background" />
      <div className={Styles['error-body']}>
        <h3>{code}</h3>
        <h2>Error</h2>
        <p>
          Sorry,&nbsp;
          {error}
        </p>
        <div>
          <Buttons />
        </div>
        {note && (
          <span className={Styles.note}>
            Note: You can try to resolve this problem by
            <a
              href="http://www.wikihow.com/Clear-Your-Browser%27s-Cookies"
              className={Styles.link}
              target="_blank"
              rel="noreferrer noopener"
            >
              clearing cookies
            </a>
          </span>
        )}
      </div>
    </div>
  );
};

export default withComponentName(ErrorPage);
