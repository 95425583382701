import i18n from 'i18next';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import Logo from '../../../assets/logo.svg';
import DarkLogo from '../../../assets/dark_mode_logo.svg';
import ErrorSvg from '../../../assets/Mask_Group.svg';
import Styles from './styles.module.scss';
import '../../../services/localisation/i18n';
import { getAppLanguage } from '../../../helpers/helpers';

const cx = classNames.bind(Styles);

const AssetNotFound = ({ railsContext, piwikEnabled }) => {
  const [hasRef, setHasRef] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTheme, setCurrentTheme] = useState('light');
  const { t } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const searchString = (railsContext?.search || '');
    const urlParams = new URLSearchParams(searchString);
    const hasRef = urlParams.get('ref');
    const appLanguage = getAppLanguage(urlParams.get('lang'));
    const urlTheme = urlParams.get('theme') || 'light';
    setCurrentTheme(urlTheme);
    if (appLanguage === 'ar') {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }
    handleLanguageChange(appLanguage);
    setHasRef(hasRef);
    if (piwikEnabled) {
      const ticker = urlParams.get('ticker');
      _paq.push(['trackEvent', 'Missed topics', 'No relevant topic was found', ticker]);
    }
  }, []);

  const handleLogoEventClick = () => {
    if (piwikEnabled) _paq.push(['trackEvent', 'CF', 'CF Click']);
  };

  const handleLogoClick = () => {
    handleLogoEventClick();
    if (window.parent !== window) {
      window.parent.postMessage(
        JSON.stringify({
          type: 'linkClicked',
          url: 'https://www.cityfalcon.ai?utm_source=etoro_news',
        }),
        '*',
      );
    }
  };

  if (isLoading) {
    return (
      <div className={cx('cf-etoro-loader', { 'dark-mode': currentTheme === 'dark' })}>
        <img alt="eToro Loader" className={cx('cf-etoro-loader-logo')} onError={(e) => window.imageError(e.nativeEvent.target, 'eLogo', 'https://etoro-cdn.etorostatic.com/web-client/img/eToro.png')} src="https://foxy.cityfalcon.com/?url=https://etoro-cdn.etorostatic.com/web-client/img/eToro.png" />
        <img alt="eToro Loader" className={cx('cf-etoro-loader-pre')} onError={(e) => window.imageError(e.nativeEvent.target, 'ePre', 'https://etoro-cdn.etorostatic.com/web-client/img/preloader.png')} src="https://foxy.cityfalcon.com/?url=https://etoro-cdn.etorostatic.com/web-client/img/preloader.png" />
      </div>
    );
  }

  return (
    <div className={cx('error_page', { 'dark-mode': currentTheme === 'dark' })}>
      <div className={Styles.error_page_header}>
        <span>{t('powered_by')}</span>
        {hasRef ? (
          <a
            href="https://www.cityfalcon.ai?utm_source=etoro_news"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLogoEventClick}
          >
            <img src={currentTheme === 'dark' ? DarkLogo : Logo} alt="CityFalcon" />
          </a>
        ) : (
          <div onClick={handleLogoClick}>
            <img src={currentTheme === 'dark' ? DarkLogo : Logo} alt="CityFalcon" />
          </div>
        )}
      </div>
      <div className={Styles.error_page_body}>
        <img src={ErrorSvg} alt="" />
        <div className={Styles.error_page_message}>
          <Trans i18nKey="noAsset">
            This assets is not yet covered by news content.
            <br />
            We&apos;ll be adding coverage soon.
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AssetNotFound);
